import { getActiveWorksheet, getTable, loadWorksheetNameID, loadTableIdName } from "./ExcelRun"
import { storage } from "./Storage";

export const getSheetContext = async () => {
  let documentID = '';
  const sheetActive = await getActiveWorksheet();
  const { settings } = Office.context.document;

  if(settings) {
    documentID = settings.get('documentId');
    Office.context.document.settings.saveAsync();
  }
  await loadWorksheetNameID(sheetActive);
  return  { SheetName: sheetActive.name, SheetID: `${documentID}|${sheetActive.id}` }
}

export const getColumnLetter = (columnIndex:number ) => {
  let temp, letter = '';
  while(columnIndex) {
    temp = (columnIndex - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    columnIndex = (columnIndex - temp - 1) / 26;
  }
  return letter
}

export const getRangeHeader = (element: any ) => {
  const keyArray = Object.keys(element);
  const startRange = 'A1';
  const endRange = getColumnLetter(keyArray.length);

  return `${startRange}:${endRange}1`;
}


export const currentSheetHasReport = async() => {
  let documentID = '';
  const { settings } = Office.context.document;

  if(settings) {
    documentID = settings.get('documentId');
    await Office.context.document.settings.saveAsync();
  }
  const sheetActive = await getActiveWorksheet();
  await loadWorksheetNameID(sheetActive);
  const table = await getTable(sheetActive);
  if(table) {
    await loadTableIdName(table);
  }

  const reports = await getReportsGenerated();

  const report = reports.find(r => r.SheetID === sheetActive.id && r.DocumentID === documentID);

  if(report) {
    return report
  }

  return null;
}

export const saveReportGenerated = async (worksheet: Excel.Worksheet, parameters: any[], report: any) => {
  let documentID = '';
  const { settings } = Office.context.document;

  if(settings) {
    documentID = settings.get('documentId');
    Office.context.document.settings.saveAsync();
  }
  await loadWorksheetNameID(worksheet);
  const table = await getTable(worksheet);
  if(table) {
    await loadTableIdName(table);
  }
  const reportGenerated = {
    DocumentID: documentID,
    SheetID: worksheet.id,
    TableID: table?.id,
    Parameters: parameters,
    ReportInfo: report
  };
  let reports = await getReportsGenerated();
  let existReport = reports.findIndex(r => r.SheetID === reportGenerated.SheetID);
  if(existReport >= 0) {
    reports[existReport] = reportGenerated
  } else {
    reports.push(reportGenerated);
  }

  await saveReportGeneratedList(reports);
}

export const saveReportGeneratedList = async(reports: any[]) => {
  storage.setItem('reportGenerated', JSON.stringify(reports));
}

export const getReportsGenerated = async() : Promise<any[]> => {
  return JSON.parse(storage.getItem('reportGenerated'))
}
