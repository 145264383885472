import { ActivityItem, ProgressIndicator, Stack, Text, PrimaryButton, Icon, Image } from '@fluentui/react';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import PwProps from '../props/PwProps';

@inject('userStore', 'uiStore')
@observer
class PwLogin extends Component<PwProps> {
  state = {
    isLoading: false
  }

  activityItemDots = [
    {
      ID: 1,
      activityDescription: <Text>Importe datos de Peopleware a Excel fácilmente</Text>,
      activityIcon: <Icon iconName='table' />
    },
    {
      ID: 2,
      activityDescription: <Text>Analice y siga los datos de su organización</Text>,
      activityIcon: <Icon iconName='chart' />
    },
    {
      ID: 3,
      activityDescription: <Text>Mantenga a su equipo informado en cuanto a la información de su empresa</Text>,
      activityIcon: <Icon iconName='chart' />
    }
  ]

  loginInPeopleware = () => {
    this.props.uiStore?.changeLoginStatus(true);
  }

  cancelLoginInPeopleware = () => {
    this.props.uiStore?.changeLoginStatus(false);
  }

  render() {
    return <div className='pw-login' style={{ padding: '16px', boxSizing: 'border-box' }}>
       <div style={{ marginBottom: '20px', height: '18px' }}>
        { this.props.uiStore?.isLogin && <ProgressIndicator /> }
       </div>
       <Stack>
         <Stack.Item >
           <Image style={{ margin: '0 auto', marginBottom: '40px'  }} src="/assets/isotipo.png" alt="Peopleware Isotipo" />
         </Stack.Item>
       </Stack>
       <Stack style={{ marginBottom: '40px' }}>
         <Stack.Item>
           <Text variant='xLarge' style={{ textAlign: 'center', display:'block' }}>
            La flexibilidad de Peopleware combinada con el poder de Excel
           </Text>
         </Stack.Item>
       </Stack>
       <Stack>
        <Stack.Item>
          { this.activityItemDots.map((item) => {
            return <ActivityItem {...item} styles={{ activityContent: { textAlign: 'left' } }} key={item.ID} />
          })
          }
        </Stack.Item>
       </Stack>
       <Stack style={{ marginTop: '40px' }}>
         <Stack.Item style={{ textAlign: 'center' }}>
           { this.props.uiStore?.isLogin ? <PrimaryButton onClick={this.cancelLoginInPeopleware}>Cancelar</PrimaryButton> : <PrimaryButton href={this.props.userStore?.connectUrl} target="_blank" onClick={this.loginInPeopleware}>
             Iniciar Sesión
           </PrimaryButton> }
         </Stack.Item>
       </Stack>
    </div>
  }
}

export default PwLogin;
