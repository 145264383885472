import { Dropdown, MessageBar, PrimaryButton, Stack } from "@fluentui/react";
import { observer, inject } from "mobx-react";
import { Component } from "react";
import PwProps from "../props/PwProps";
import { api } from "../utils/Api";
import PwLoading from "./PwLoading";
import PwSheetHeader from "./PwSheetHeader";

interface PwImportProps extends PwProps{}

interface PwImportState {
  modules: any[];
  reports: any[];
  reportSelected: any,
  isLoading: boolean
}

@inject('uiStore')
@observer
export default class PwImport extends Component<PwImportProps, PwImportState> {

  constructor(props:any) {
    super(props);
    this.state = {
      modules: [],
      reports: [],
      reportSelected: null,
      isLoading: false
    }
  }
  async componentDidMount() {
    await this.getReportsMenu();
  }

  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }

  async getReportsMenu() {
    this.toggleLoading();
    const items =  await api.Get('Menu/GetAddInItems').then(({data}) => data.Data)
    this.setState({
      modules: items.reduce((prev:any, curr:any) => {
        const exists = prev.find((x:any) => x.Code === curr.Code);
        if(!exists) {
          prev.push(curr);
        }
        return prev
      }, []).map((x:any) => ({ key: x.Code, text: x.Description }))
    })
    this.toggleLoading();
  }

  async onChangeModule(element: any, option:any, optionIndex: any) {
    if (option) {
      this.toggleLoading();
      console.log('getting reports in module')
      const reports = await api.OData(`ODataReports/GetReportsInModule(module='${option.key}')`)
        .then(({data}) => data.value as (any[]))
        .catch((err) => {
          console.log('Ocurrió un error en onChangeModule');
          console.error(err);

          this.props.uiStore?.setDialogError('Ocurrió un error al cargar los reportes en el módulo.');
          return [] as (any[]);
        });

      console.log(reports)
      this.setState({
        reports: reports.map((x:any) => ({ key: x.ID, text: x.Title, description: x.Description, tableName: x.Name }))
      })
      this.toggleLoading();
    }
  }

  onSelectedModule(element: any, option:any, optionIndex: any) {
    this.setState({
      reportSelected: option
    });

    this.props.uiStore?.setReportSelected(option);
  }

  goToGenerateReport() {
    this.props.uiStore?.changeSection('report');
  }

  render() {
    return <div>
      { this.state.isLoading && <PwLoading />}
      <PwSheetHeader />
      <Stack style={{ marginBottom: '24px'}}>
        <Stack.Item>
          <MessageBar dismissButtonAriaLabel="Close">
            Seleccione la aplicación, módulo o catálogo del que proviene el reporte y el reporte que desee generar.
          </MessageBar>
        </Stack.Item>
      </Stack>
      <Stack style={{ marginBottom: '24px' }}>
        <Dropdown label="Aplicación" options={this.state.modules} onChange={(...args) => this.onChangeModule(...args)}/>
      </Stack>
      <Stack style={{ marginBottom: '24px' }}>
        <Dropdown disabled={ this.state.reports.length === 0 } label="Reporte" options={this.state.reports} onChange={(...args) => this.onSelectedModule(...args)} />
      </Stack>
      {this.state.reportSelected && <Stack>
        <MessageBar>
          { this.state.reportSelected.description }
        </MessageBar>
      </Stack>}
      <Stack style={{ marginTop: '40px'}}>
        <Stack.Item style={{ textAlign: 'center' }}>
          <PrimaryButton disabled={!this.state.reportSelected} onClick={() => this.goToGenerateReport()}>
            Siguiente
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </div>
  }
}
