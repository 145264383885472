import * as Sentry from '@sentry/react';
import { getColumnLetter } from './PwOffice';

export const getMessage = (error: { code: Excel.ErrorCodes, message: string, stack: string }, methodName?: string) => {
  if(methodName) {
    Sentry.configureScope((scope) => {
      scope.setTag('ExcelRun.Method', methodName);
      scope.setExtra('ExcelRun.Method',methodName)
    });
  }

  if(error.code === Excel.ErrorCodes.generalException) {
    Sentry.configureScope((scope) => {
      scope.setTag('ExcelRun.Message', error.message);
    });
    return 'General Exception';
  }

  if(error.code === Excel.ErrorCodes.invalidArgument) {
    return 'The Argument is invalid or missing or has an incorrect format.'
  }

  if(error.code === Excel.ErrorCodes.invalidOperationInCellEditMode) {
    return 'Excel is in cell-editing mode. Please exit the edit mode by pressing ENTER or TAB or selecting another cell, and then try again.'
  }

  return error.message;
}

export const getActiveWorksheet = async () : Promise<Excel.Worksheet> => {
  try {
    return await Excel.run(async (context) => {
      const workSheet = context.workbook.worksheets.getActiveWorksheet();
      context.trackedObjects.add(workSheet);

      return workSheet;
    })
  } catch (error) {
    error.message = getMessage(error, `getActiveWorksheet()`)
    throw error;
  }
}

export const loadWorksheetNameID = async (workSheet: Excel.Worksheet) : Promise<void> => {
  try {
    await Excel.run(async (_) => {
      workSheet.load('id,name');
      await workSheet.context.sync();
    })
  } catch (error) {
    console.log('error in ws name id')
    // error.message = getMessage(error, `loadWorksheetNameID(${workSheet})`)
    // throw error;
    return;
  }
}

export const createTable = async(workSheet: Excel.Worksheet, tableAddress: string, columnHeaders: string[], tableName: string) : Promise<Excel.Table> =>  {
  try {
    if(!excelApiSupported('1.2')) {
      return await createTableLowVersion(workSheet, tableAddress, columnHeaders, tableName);
    }

    return await Excel.run(workSheet, async (context) => {
      const table = workSheet.tables.add(tableAddress, true /* HasHeaders */);
      table.getHeaderRowRange().values = [columnHeaders];
      table.name = tableName;
      context.trackedObjects.add(table);
      await context.sync();
      return table;
    });
  } catch (error) {
    error.message = getMessage(error);
    throw error;
  }
}

const createTableLowVersion = async(workSheet: Excel.Worksheet, tableAddress: string, columnHeaders: string[], tableName: string) : Promise<Excel.Table> => {
  return await Excel.run(async (context) => {
    const table = workSheet.tables.add(tableAddress, true /* HasHeaders */);
    table.getHeaderRowRange().values = [columnHeaders];
    table.name = tableName;
    context.trackedObjects.add(table);
    await context.sync();
    return table;
  })
}

export const getTable = async(worksheet: Excel.Worksheet) : Promise<Excel.Table | null> => {
  try {
    if(!excelApiSupported('1.9')) {
      return await getTableLowVersion(worksheet);
    }

    return await Excel.run(worksheet, async(context) => {
      const topLeftCell = await worksheet.getRange('A1:A1');
      const tableCollection = topLeftCell.getTables(false);
      tableCollection.load('items');
      await context.sync();
      let table = tableCollection.items[0];
      if(table) {
        context.trackedObjects.add(table);
      }
      await context.sync();
      return table;
    })
  } catch (error) {
    if(error.code === Excel.ErrorCodes.itemNotFound) {
      return null;
    }
    error.message = getMessage(error, `getTable()`)
    throw error
  }
}

const getTableLowVersion = async(worksheet: Excel.Worksheet) : Promise<Excel.Table | null> => {
  return await Excel.run( async(context) => {
    const tableCollection = worksheet.tables;
    tableCollection.load('items');

    try {
      await tableCollection.context.sync();
    } catch (e) {
      await worksheet.context.sync();
    }
    await context.sync();

    let table = tableCollection.items[0];
    if(table) {
      context.trackedObjects.add(table);
    }
    try {
      await tableCollection.context.sync();
    } catch (e) {
      await worksheet.context.sync();
    }
    await context.sync();
    return table;
  });
}

export const clearTable = async(table: Excel.Table) => {
  try {
    if(!excelApiSupported('1.2')) {
      return await Excel.run(async (context) => {
        const tableRange = table.getDataBodyRange();
        await table.context.sync();
        tableRange.load(['rowCount']);
        await context.sync();

        if (tableRange.rowCount === 1) {
          tableRange.clear();
        } else {
          tableRange.delete('Up');
        }
        return await context.sync();
      });
    }

    return await Excel.run(table, async (context) => {
      const tableRange = table.getDataBodyRange();
      tableRange.load(['rowCount']);
      await context.sync();

      if(tableRange.rowCount === 1) {
        tableRange.clear();
      } else {
        tableRange.delete('Up');
      }
      return await context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, `clearTable()`)
    throw error
  }
}

export const resizeHeaders = async(table: Excel.Table, newHeaders: string[], worksheet: Excel.Worksheet) : Promise<void> => {
  try {
    if(!excelApiSupported('1.2')) {
      return await resizeHeadersLowVersion(table, newHeaders, worksheet);
    }

    return await Excel.run(table, async(context) => {
      const tableRange = table.getRange();
      tableRange.load("columnCount");
      await context.sync();

      // Verify if columns length are different to resize
      if (tableRange.columnCount !== newHeaders.length) {
        // Get the difference (delta column) to resize table for new headers
        const diff = newHeaders.length - tableRange.columnCount;
        const newRange = tableRange.getResizedRange(0, diff);
        table.resize(newRange);

        // If table was shortened, the remaining data is removed
        if (diff < 0) {
          tableRange.load("rowCount");
          await context.sync();

          const start = getColumnLetter(newHeaders.length + 1);
          const end = getColumnLetter(newHeaders.length + Math.abs(diff));
          // Select outer range to delete
          const toDelete = worksheet.getRange(`${start}1:${end}${tableRange.rowCount + 1}`);

          toDelete.delete(Excel.DeleteShiftDirection.up);
          await context.sync();
        }

        // Assign new header values
        table.getHeaderRowRange().values = [newHeaders];
        return context.sync();
      }
      return;
    });
  } catch (error) {
    error.message = getMessage(error, 'resizeHeaders()')
    throw error
  }
}

const resizeHeadersLowVersion = async(table: Excel.Table, newHeaders: string[], worksheet: Excel.Worksheet) : Promise<void> => {
  const tableRange = table.getRange();
  tableRange.load("columnCount");
  await table.context.sync();

  // Verify if columns length are different to resize
  if (tableRange.columnCount !== newHeaders.length) {
    // Get the difference (delta column) to resize table for new headers
    const diff = newHeaders.length - tableRange.columnCount;
    const newRange = tableRange.getResizedRange(0, diff);
    table.resize(newRange);

    // If table was shortened, the remaining data is removed
    if (diff < 0) {
      tableRange.load("rowCount");
      await table.context.sync();

      const start = getColumnLetter(newHeaders.length + 1);
      const end = getColumnLetter(newHeaders.length + Math.abs(diff));
      // Select outer range to delete
      const toDelete = worksheet.getRange(`${start}1:${end}${tableRange.rowCount + 1}`);

      toDelete.delete(Excel.DeleteShiftDirection.up);
      await table.context.sync();
    }

    // Assign new header values
    table.getHeaderRowRange().values = [newHeaders];
    return table.context.sync();
  }
  return;
}

export const initRecordsInTable = async (table: Excel.Table, vals: any[][]) => {
  try {
    if(!excelApiSupported('1.2')) {
      return await initRecordsInTableLowVersion(table, vals);
    }

    return await Excel.run(table, async (context) => {
      if (vals.length >= 1) {
        table.getDataBodyRange().getRow(0).values = [vals[0]];
      }

      if (vals.length > 1) {
        table.getDataBodyRange().getRowsBelow(vals.length - 1).values = vals.slice(1);
      }
      await context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, `initRecordsInTable()`)
    throw error;
  }
}

const initRecordsInTableLowVersion = async (table: Excel.Table, vals: any[][]) => {
  return await Excel.run(async (context) => {
    const bodyRange = table.getDataBodyRange();
    if (vals.length === 1) {
      bodyRange.getRow(0).values = [vals[0]];
      await context.sync();
      await table.context.sync();
    }

    if (vals.length > 1) {
      for (let pos = 0; pos < vals.length; pos++) {
        try {
          table.rows.add(pos, [vals[pos]]);
        } catch (e) {
          console.error(e);
          return;
        }
      }
      await context.sync();
      await table.context.sync();
    }
    await context.sync();
  });
}

export const addRecordsInTable = async (table: Excel.Table, vals: any[][]) => {
  try {
    if(!excelApiSupported('1.9')) {
      return await addRecordsInTableLowVersion(table, vals);
    }

    return await Excel.run(table, async(context) => {
      table.getDataBodyRange().getRowsBelow(vals.length).values = vals;
      await context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, `addRecordsInTable()`)
    throw error;
  }
}

const addRecordsInTableLowVersion = async (table: Excel.Table, vals: any[][]) => {
  return await Excel.run(async(_) => {
    table.getDataBodyRange().getRowsBelow(vals.length).values = vals;
    await table.context.sync();
  });
}

export const getWorksheet = async (key: string) : Promise<Excel.Worksheet> => {
  try {
    return await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getItem(key);
      context.trackedObjects.add(worksheet);
      return worksheet;
    })
  } catch (error) {
    error.message = getMessage(error, `getWorksheet()`)
    throw error;
  }
}

export const getRangeByAddress = async (worksheet: Excel.Worksheet, address: string) => {
  try {
    if(!excelApiSupported('1.2')) {
      return await Excel.run(async(_) => {
        return worksheet.getRange(address);
      });
    }

    return await Excel.run(worksheet, async(context) => {
      const range = worksheet.getRange(address);
      context.trackedObjects.add(range);
      return range;
    })
  } catch (error) {
    throw error;
  }
}

export const getUsedRange = async(workSheet: Excel.Worksheet) : Promise<Excel.Range> => {
  try {
    if(!excelApiSupported('1.2')) {
      return await Excel.run(async (_) => {
        return workSheet.getUsedRange();
      });
    }
    return await Excel.run(workSheet, async (context) => {
      const range = workSheet.getUsedRange();
      context.trackedObjects.add(range);
      return range;
    })
  } catch (error) {
    error.message = getMessage(error, `getUsedRange()`)
    throw error;
  }
}

export const activateWorksheet = async(worksheet: Excel.Worksheet) => {
  try {
    if(!excelApiSupported('1.2')) {
      return await Excel.run(async(_) => {
        worksheet.activate();
        return worksheet.context.sync();
      });
    }

    return await Excel.run(worksheet,async(context) => {
      worksheet.activate();
      return context.sync();
    })
  } catch (error) {
    throw error;
  }
}

export const onWorksheetChanged = async (func: (args: Excel.WorksheetChangedEventArgs) => Promise<any>) => {
  if(!excelApiSupported('1.9')) { return null }
  try {
    await Excel.run(async(context) => {
      context.workbook.worksheets.onChanged.add(func);
      return context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, 'onWorksheetChanged()')
    throw error
  }
}

export const onActivated = async(func: (args:any) => Promise<any>) => {
  if(!excelApiSupported('1.7')) {

    return null
  }
  try {
    return await Excel.run(async(context) => {
      const sheets = context.workbook.worksheets;
      const event = sheets.onActivated.add(func);
      const eventObj = sheets.onDeactivated.add((args) => Excel.run(async (ctx) => {}));
      eventObj.remove();
      await context.sync();
      return event;
    })
  } catch (error) {
    error.message = getMessage(error, 'onActivated()')
    throw error;
  }
}


export const reapplyAutoFilter = async (table: Excel.Table) => {
  if(!excelApiSupported('1.9')) { return null }

  try {
    return await Excel.run(table, async (context) => {
      table.autoFilter.reapply();
      await context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, 'reapplyAutoFilter()')
    throw error;
  }
}

export const removeAutoFilter = async(table: Excel.Table) => {
  if(!excelApiSupported('1.9')) {
    return;
  }

  try {
    return await Excel.run(table, async(context) => {
      table.clearFilters();
      await context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, 'removeAutoFilter()')
    throw error;
  }
}

export const loadTableIdName = async (table: Excel.Table) => {
  try {
    if(!excelApiSupported('1.9')) {
      return await loadTableIdNameLowVersion(table);
    }

    return await Excel.run(table, async(context) => {
      table.load(['id', 'name']);
      await context.sync();
    })
  } catch (error) {
    error.message = getMessage(error, 'getTableIdName()')
    throw error;
  }
}

const loadTableIdNameLowVersion = async (table: Excel.Table) => {
  return await Excel.run(async(_) => {
    table.load(['id', 'name']);
    await table.context.sync();
  });
}

export const excelApiSupported = (version: string) => {
  const { requirements } = Office.context;
  return requirements && requirements.isSetSupported('ExcelApi', version);
}

export const createWorksheet = async(name: string) => {
  try {
    return await Excel.run(async(context) => {
      const worksheet = context.workbook.worksheets.add(name);
      worksheet.load('name');
      await context.sync();
      return worksheet;
    })
  } catch (error) {
    throw error;
  }
}

export const getAllTableNames = async() => {
  try {
    return await Excel.run(async(context) => {
      const tableNames: string[] = [];
      const tables = context.workbook.tables.load('items');
      await context.sync();

      for (let table of tables.items) {
        table.load('name');
        tableNames.push(table.name);
      }
      return tableNames;
    });
  } catch (error) {
    throw error;
  }
}
