import { mergeStyleSets } from "@fluentui/merge-styles";
import { Spinner } from "@fluentui/react";
import { Component } from "react";

const classNames = mergeStyleSets({
  PwLoading: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: 'rgba(255,255,255,0.50)',
    alignItems: 'center',
    justifyContent: 'center'
  }
})


export default class PwLoading extends Component {
  render() {
    return <div className={classNames.PwLoading}>
      <Spinner />
    </div>
  }
}