import { mergeStyleSets, ProgressIndicator } from "@fluentui/react";
import { observer, inject } from "mobx-react";
import { Component } from "react";
import PwProps from "../props/PwProps";

const classNames = mergeStyleSets({
  toast: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.4)'
  },
  content: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.16)'
  }
})

@inject('importStore')
@observer
export default class PwToast extends Component<PwProps> {
  render() {
    return <div className={classNames.toast}>
      <div className={classNames.overlay}>
      </div>
      <div className={classNames.content}>
        <ProgressIndicator label="Importando información desde Peopleware" description={this.props.importStore?.stepLabel}  />
      </div>
    </div>
  }
}