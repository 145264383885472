import { observable, action, configure, makeObservable} from "mobx";
import { onActivated } from "../utils/ExcelRun";
import { currentSheetHasReport, getSheetContext } from "../utils/PwOffice";

configure({ enforceActions: 'never' })


export default class UIStore {
  @observable section : string = 'login';
  @observable sectionTitle? = 'Generar reportes';
  @observable isLoaded = false;
  @observable reportSelected = { key: '', text: '', tableName: '' };
  @observable sheetTitle = { SheetName: '', SheetID: '' }
  @observable parametersSaved : any[] = [];
  @observable existingReport = false;
  @observable logOutUrl = "";
  @observable isLogin = false;
  @observable modalText = { Title: "", Description: "" }
  @observable showEmptyDialog = false;
  @observable forceReplaceSheet = false;
  @observable actionErrorMsg = "";

  constructor() {
    makeObservable(this);
  }

  titles : any = {
    import: 'Generar reportes',
    setting: 'Ajustes',
  }

  @action setTextForUpdateTable() {
    this.modalText = {
      Title: "¿Remplazar hoja actual?",
      Description: `Está a punto de remplazar toda la
      información de la hoja actual. ¿Está seguro
      de que desea continuar?`
    }
  }

  @action dismissDialogEmpty() {
    this.showEmptyDialog = false;
  }

  @action setDialogError(message: string) {
    this.actionErrorMsg = message;
  }

  @action dismissDialogError() {
    this.actionErrorMsg = "";
  }

  @action setTextForCreateSheet() {
    this.modalText = {
      Title: "¿Crear hoja nueva?",
      Description: `Existe un tabla en la hoja actual, al crear el nuevo reporte se creara una nueva hoja.`
    }
  }

  @action changeLoginStatus(status: boolean) {
    this.isLogin = status;
  }

  @action async onActivatedSheet(...args:any) {
    this.sheetTitle = await getSheetContext();
    await this.checkIfExistsReport()
  }

  @action async setSheetTitle() {
    this.sheetTitle = await getSheetContext();
    this.isLoaded = true;
    await onActivated((...args) => this.onActivatedSheet(...args));
    await this.checkIfExistsReport();
  }

  @action setParameterSaved(parameters: any[]) {
    this.existingReport = true;
    this.parametersSaved = parameters;
  }

  @action async checkIfExistsReport() {
    const reportExisting = await currentSheetHasReport();
    if(reportExisting) {
      if(this.section !== 'login') {
        this.setReportSelected(reportExisting.ReportInfo);
        await this.changeSection('report');
        this.parametersSaved = reportExisting.Parameters;
        this.existingReport = true;
      }

    } else {
      this.existingReport = false;
      this.setReportSelected({ key: '', text: '' });
      if(this.section !== 'login') {
        await this.changeSection('import');
      }
    }
  }

  @action setReportSelected(reportSelected:any) {
    this.reportSelected = reportSelected;
  }

  @action async changeSection(section: string) {
    if(this.section === 'report' && section === 'import') {
      this.parametersSaved = [];
      this.existingReport = false;
    }

    if(this.section === 'login' && section === 'import') {
      this.section = section;
      await this.checkIfExistsReport();
      return;
    }

    this.section = section;

    if(section in this.titles) {
      this.sectionTitle = this.titles[section];
    } else if(section === 'report') {
      this.sectionTitle = this.reportSelected.text;
    }
  }

  @action activateForceReplaceSheet() {
    this.forceReplaceSheet = true;
  }
}
