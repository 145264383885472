import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/jsdom';

import { v4 } from 'uuid';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import prefilled from './utils/prefilled';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@uifabric/icons';
import { Provider } from 'mobx-react';
import UIStore from './stores/UIStore';
import UserStore from './stores/UserStore';
import ImportStore from './stores/ImportStore';
import './index.css';
import SocketCommunication from './utils/SocketCommunication';
import { storage } from './utils/Storage';

initializeIcons();
const socketCommunication = new SocketCommunication();

const uiStore = new UIStore();
const userStore = new UserStore(socketCommunication, uiStore);
const importStore = new ImportStore(uiStore);
const stores = {
  uiStore,
  userStore,
  importStore
}


const render =  (Component:any) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider {...stores} socketCommunication={socketCommunication}>
        <Component />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

(async () => {
  await Office.onReady();
  Sentry.init({
    dsn: prefilled.sentryUrl,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    debug: true,
  });

  const context = Office.context;

  console.log('ready to load context ExcelApi')
  if(context) {
    // console.log('contexting');
    // console.log('diagnostics');
    // console.log(context.diagnostics);
    if (context.diagnostics) {
      console.log('Loading diagnostics')
      const {host, platform, version} = context.diagnostics;
      // console.log('requirements');
      const {requirements} = context;

      // console.log('configuring scopes');
      Sentry.configureScope((scope) => {
        scope.setTag('Excel.Host', host);
        scope.setTag('Excel.Platform', platform);
        scope.setTag('Excel.Version', version);

        if (requirements) {
          const excelApiRequirements = ['1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9', '1.10', '1.11', '1.12'].filter((s) => requirements.isSetSupported('ExcelApi', s));
          const excelDialogRequirements = ['1.1', '1.2'].filter(s => requirements.isSetSupported('DialogApi', s));
          const excelCustomFunctionsRuntimeRequirements = ['1.1', '1.2', '1.3', '1.4'].filter(s => requirements.isSetSupported('CustomFunctionRuntime', s));

          scope.setExtra('Excel.Api', excelApiRequirements);
          scope.setExtra('Excel.DialogApi', excelDialogRequirements);
          scope.setExtra('Excel.CustomFunctions', excelCustomFunctionsRuntimeRequirements);
        }
      });
    } else {
      // console.log('else scope');
      Sentry.configureScope((scope) => {
        scope.setTag('Excel.Host', Office.HostType.Excel);
        scope.setTag('Excel.Platform', Office.PlatformType.PC);
        scope.setTag('Excel.Version', '16.0.4266.1001');

        // if (requirements) {
        //   const excelApiRequirements = ['1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9', '1.10', '1.11', '1.12', '1.13', '1.14'].filter((s) => requirements.isSetSupported('ExcelApi', s));
        //   const excelDialogRequirements = ['1.1', '1.2'].filter(s => requirements.isSetSupported('DialogApi', s));
        //   const excelCustomFunctionsRuntimeRequirements = ['1.1', '1.2', '1.3', '1.4'].filter(s => requirements.isSetSupported('CustomFunctionRuntime', s));
        //
        //   console.log('Excel apis');
        //   console.log(excelApiRequirements);
        //   if (excelApiRequirements.length > 0) scope.setExtra('Excel.Api', excelApiRequirements);
        //   if (excelDialogRequirements.length > 0) scope.setExtra('Excel.DialogApi', excelDialogRequirements);
        //   if (excelCustomFunctionsRuntimeRequirements.length > 0) scope.setExtra('Excel.CustomFunctions', excelCustomFunctionsRuntimeRequirements);
        // }
      });
    }
  }

  const settings = Office.context.document.settings;

  if(settings) {
    console.log('settings Office');
    Office.context.document.settings.set('Office.AutoShowTaskPaneWithDocument', true);
    const documentID = Office.context.document.settings.get('documentId');

    if(!documentID) {
      Office.context.document.settings.set('documentId', v4());
    }

    await Office.context.document.settings.saveAsync()
  }

  let reports = storage.getItem('reportGenerated');

  if(!reports) {
    storage.setItem('reportGenerated', JSON.stringify([]))
  }

  console.log('ready to render App');
  render(App);
  reportWebVitals();
})();

