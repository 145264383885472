import { io } from 'socket.io-client';
import prefilled from './prefilled';
import { storage } from './Storage';
import { tokenProcess } from './Token';


export default class SocketCommunication   {
  private socketUrl = "";
  private socket:any;
  private retries: number;

  constructor() {
    this.retries = 0;
    this.socketUrl = prefilled.socketUrl;

    const isSecure = this.socketUrl.startsWith('https');
    console.log('creando socket en: ', this.socketUrl, '| isSecure: ', isSecure);
    this.socket = io(this.socketUrl, {
      path: '/.sock',
      secure: isSecure,
      transports: ['polling']
    });
    this.socket.on('connect',() => this.onConnect());
    this.socket.on('connect_error', () => {
      // revert to classic upgrade
      if (this.retries <= 3) {
        console.log('Error al conectar, reintentando!');
        this.socket.io.opts.transports = ['websocket', 'polling'];
        this.retries += 1;
      } else {
        // this.socket.close();
        console.error('No se pudo conectar el socket');
      }
    });
  }

  on(event:string, callback: Function) {
    this.socket.on(event, callback);
  }

  emit(event: string, ...args:any ) {
    this.socket.emit(event, ...args)
  }

  private async onConnect() {
    // console.log('socket conectado')
    try {
      // console.log('socket greeting')
      // storage.clearAll();
      let greeting = await tokenProcess();
      try {
        // console.log('emitiendo token por socket')
        this.socket.emit("token",JSON.stringify(greeting));
      } catch (error) {
        console.log('Error on emitting token')
        console.error(error);
      }
    } catch (error) {
      console.log('error al recolectar token procesado')
      console.log(error)
      storage.removeItem('PwToken');
      setTimeout( () => {
        this.onConnect();
      }, 5000);
    }
  }
}
