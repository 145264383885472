import { action, observable, configure, makeObservable } from "mobx";
import SocketCommunication from "../utils/SocketCommunication";
import { storage } from "../utils/Storage";
import { tokenProcess } from "../utils/Token";
import UIStore from "./UIStore";

configure({ enforceActions: 'never' })

export default class UserStore {
  socket: SocketCommunication;
  uiStore: UIStore;
  @observable userIsLogged = false;
  @observable isLoaded = false;
  @observable connectUrl = '';
  constructor(Socket: SocketCommunication, uiStore: UIStore) {
    this.uiStore = uiStore;
    this.socket = Socket
    this.socket.on('setLoginUrl', ({ url } : any ) => this.setUrl(url))
    this.socket.on('userIsLogged', (token:any) => this.setTokenAndLoggedUser(JSON.parse(token)))
    this.socket.on('destroySession', () => this.destroySession())
    this.socket.on('urlLogout', (url:string) => this.setLogout(url))
    makeObservable(this);
  }

  @action setLogout(url: string) {
    this.uiStore.logOutUrl = url;
  }
  @action async destroySession() {
    storage.removeItem('tenantid');
    storage.removeItem('token');
    this.uiStore.changeSection('login');
    this.userIsLogged = false;
    this.uiStore.changeLoginStatus(false);

    const gretting = await tokenProcess();
    this.socket.emit('token', JSON.stringify(gretting));

  }

  @action setTokenAndLoggedUser(token: any) {
    storage.setItem('token', token.token);
    storage.setItem('tenantid', token.tenantid);
    this.userIsLogged = true;
    this.uiStore.changeSection('import');

  }

  @action setUrl(url: string) {
    this.connectUrl = url;
    this.setAsLoaded();
    this.uiStore.setSheetTitle();
  }

  @action setAsLoaded() {
    this.isLoaded = true;
  }

}
