import { Spinner, Stack, mergeStyleSets } from '@fluentui/react';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import PwImport from './components/PwImport';
import PwLogin from './components/PwLogin';
import PwMenu from './components/PwMenu';
import PwReport from './components/PwReport';
import PwProps from './props/PwProps';

const styles = mergeStyleSets({
  root: {
    height: 'calc(100% - 40px)',
  },
  renderSection: {
    width: '100%',
    overflow: 'auto',
    padding: '0 16px'
  },
  spinnerSection: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0
  }
})

@inject('uiStore', 'userStore', 'importStore', 'socketCommunication')
@observer
class App extends Component<PwProps> {
  render() {
    const hasAuth = this.props.userStore?.userIsLogged;
    const section = this.props.uiStore?.section;

    const isAppReady = () => {
      return (
        this.props.uiStore?.isLoaded
        && this.props.userStore?.isLoaded
      )
    }


    const renderSection = () => {
      if(isAppReady()) {
        switch(section) {
          case 'login':
            return <PwLogin />
          case 'import':
            return <PwImport />
          case 'report':
            return <PwReport />
        }
      } else {
        return (
          <Stack verticalAlign='center' grow={1} className={styles.spinnerSection}>
            <Spinner />
          </Stack>
        )
      }
    }
    return (
      <div className="App">
        { isAppReady() && hasAuth ? <Stack>
          <Stack.Item>
            <PwMenu />
          </Stack.Item>
        </Stack> : null}
        <Stack>
          <Stack verticalFill grow={1} className={styles.renderSection}>
            { renderSection() }
          </Stack>
        </Stack>
      </div>
    )
  }
}

export default App;
