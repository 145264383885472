import { DatePicker, MessageBar, Stack, TextField, Label, PrimaryButton, Dialog, DialogType, DialogFooter, DefaultButton } from "@fluentui/react";
import { observer, inject } from "mobx-react";
import { Component } from "react";
import PwProps from "../props/PwProps";
import PwSheetHeader from "./PwSheetHeader";
import { DayPickerStrings } from './../utils/DayPickerStrings';
import PwToast from "./PwToast";
import PwLoading from "./PwLoading";
import { reaction } from "mobx";

interface PwReportState {
  parameters: any[],
  isLoadingParameters: boolean
}


@inject('uiStore', 'importStore')
@observer
export default class PwReport extends Component<PwProps, PwReportState> {
  constructor(props:any) {
    super(props)

    this.state = {
      parameters: [],
      isLoadingParameters: false
    }
  }

  async componentDidMount() {
    reaction(() => this.props.uiStore?.reportSelected, async () => {
      await this.getParameters();
    })
    await this.getParameters();
  }

  async getParameters() {
    await this.props.importStore?.getParameters();

    if ((this.props.importStore?.parameters || []).length === 0 && !(this.props.uiStore?.reportSelected || {}).key) {
      this.props.uiStore?.activateForceReplaceSheet();
      this.props.uiStore?.changeSection('import');
    }
  }

  async getData() {
    const parameters = this.props.importStore?.parameters.map((x:any) => ({ Name: x.Name,Value: x.Value, Type: x.Type }));

    this.props.importStore?.getData(parameters || []);
  }

  async validateTable() {
    const someIsRequiredAndNotValue = this.props.importStore?.parameters.some(x => x.Required && !x.Value);
    if(someIsRequiredAndNotValue) {
      this.props.importStore?.parameters.forEach((x:any) => {
        if(x.Required && !x.Value) {
          x.errorMessage = `el campo ${x.Title} es requerido`
        }
      })
      return;
    } else {
      this.props.importStore?.parameters.forEach((x:any) => {
        x.errorMessage = "";
      })
    }
    const parameters = this.props.importStore?.parameters.map((x:any) => ({ Name: x.Name,Value: x.Value, Type: x.Type }));

    this.props.importStore?.validateTable(parameters || []);
  }

  onFormatDate(date?: Date) {
    if(!date) {
      return ''
    }
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    if(Number(day) < 10) {
      day = `0${day}`
    }

    if(Number(month) < 10) {
      month = `0${month}`
    }
    return !date ? '' : `${day}/${month}/${year}`
  }

  render() {
    const dialogContentNoContent = {
      type: DialogType.normal,
      title: "No se encontraron registros",
      subText: "No se encontraron registros de acuerdo a los parámetros seleccionados"
    }
    const dialogContentProps = {
      type: DialogType.normal,
      title: this.props.uiStore?.modalText.Title,
      subText: this.props.uiStore?.modalText.Description,
    };
    const dialogErrorProps = {
      type: DialogType.normal,
      title: "Ha ocurrido algún error",
      subText: this.props.uiStore?.actionErrorMsg,
    };

    return <div>
      <PwSheetHeader />
      <Stack style={{ marginBottom: '24px'}}>
        <Stack.Item>
          <MessageBar dismissButtonAriaLabel="Close">
          Seleccione los parámetros correspondientes al reporte que desee generar.
          </MessageBar>
        </Stack.Item>
      </Stack>

        {this.props.importStore?.parameters.map((param:any) => {
          let component = null;
          const options = {
            label: param.Title,
            //isRequired: param.Required,
            required: param.Required,
            onChange: (e:any) => (param.Value = e.target.value),
            onSelectDate: (e:any) => (param.Value = e),
            errorMessage: param.errorMessage,

          }

          switch (param.Type) {
            case "Date":
              component = <DatePicker value={param.Value || null} showWeekNumbers={false} showMonthPickerAsOverlay={true} {...options} formatDate={(date?: Date) => this.onFormatDate(date)} strings={DayPickerStrings} placeholder="Seleccione una fecha"/>;
              break;
            case "String":
              component = <TextField defaultValue={param.Value || ''} {...options}/>
              break;
            default:
              break;
          }
          return <Stack style={{ marginBottom: '16px' }} key={param.ID}>
            { component }
            {param.Description && <Label disabled style={{ fontSize: '12px' }}>{ param.Description }</Label>}
          </Stack>
        })}
        <Stack style={{ marginTop: '20px' }}>
          <Stack.Item style={{ textAlign: 'center' }}>
            <PrimaryButton onClick={() => this.validateTable()}>
              { this.props.uiStore?.existingReport ? 'Actualizar reporte' : 'Generar reporte'}
            </PrimaryButton>
          </Stack.Item>
        </Stack>

      { this.props.importStore?.getInParameters && <PwLoading /> }

      { this.props.importStore?.isImporting && <PwToast /> }

      <Dialog dialogContentProps={dialogContentProps} hidden={!this.props.importStore?.showDialogConfirm}>
        <DialogFooter >
          <PrimaryButton text="Continuar" onClick={() => this.getData()} />
          <DefaultButton text="Cancelar"  onClick={() => this.props.importStore?.dismissDialogConfirm()}/>
        </DialogFooter>
      </Dialog>

      <Dialog dialogContentProps={dialogContentNoContent} hidden={!this.props.uiStore?.showEmptyDialog}>
        <PrimaryButton text="Continuar" onClick={() => this.props.uiStore?.dismissDialogEmpty()}/>
      </Dialog>

      <Dialog dialogContentProps={dialogErrorProps} hidden={!this.props.uiStore?.actionErrorMsg}>
        <PrimaryButton text="Aceptar" onClick={() => this.props.uiStore?.dismissDialogError()}/>
      </Dialog>
    </div>
  }
}
