import { makeObservable, configure, observable, action } from "mobx";
import { api } from "../utils/Api";
import {
  createTable,
  getWorksheet,
  getTable,
  clearTable,
  initRecordsInTable,
  reapplyAutoFilter,
  removeAutoFilter,
  createWorksheet,
  activateWorksheet,
  resizeHeaders,
  getAllTableNames
} from '../utils/ExcelRun';
import { currentSheetHasReport, getRangeHeader, saveReportGenerated } from "../utils/PwOffice";
import UIStore from "./UIStore";

configure({ useProxies: "never", enforceActions: 'never' })

export default class ImportStore {
  @observable isImporting = false;
  @observable percentage = 0;
  @observable stepLabel = ""
  @observable showDialogConfirm = false;
  @observable parameters: any[] = [];
  @observable getInParameters = false
  uiStore: UIStore;

  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
    makeObservable(this);
  }

  transformData(records: any[]) {
    let structure = Object.keys(records[0]);
    let recordsMapped : any[][] = [];

    for (let index = 0; index < records.length; index++) {
      const record = records[index];
      let recordMapped : any[] = [];

      for (let structureIndex = 0; structureIndex < structure.length; structureIndex++) {
        const key = structure[structureIndex];
        recordMapped.push(record[key]);
      }

      recordsMapped.push(recordMapped);
    }

    return recordsMapped;

  }

  @action dismissDialogConfirm() {
    this.showDialogConfirm = false;
  }

  @action async validateTable(parameters: any[]) {
    const worksheetName = this.uiStore.sheetTitle.SheetName;
    const reportExisting = await currentSheetHasReport();
    const worksheet = await getWorksheet(worksheetName);

    let table : Excel.Table | null =  await getTable(worksheet);
    if (table) {
      const { reportSelected, forceReplaceSheet } = this.uiStore;

      if (reportExisting && (reportSelected.key === reportExisting.ReportInfo.key || forceReplaceSheet)) {
        this.uiStore.setTextForUpdateTable();
      } else {
        this.uiStore.setTextForCreateSheet();
      }
      this.showDialogConfirm = true;
    } else {
      await this.getData(parameters);
    }
  }

  @action async getParameters() {
    if (!this.uiStore?.reportSelected || !this.uiStore?.reportSelected.key) {
      // this.uiStore?.changeSection('import');
      return;
    }

    this.getInParameters = true;
    const reportExisting = await currentSheetHasReport();
    if (reportExisting && reportExisting.ReportInfo.key === this.uiStore.reportSelected.key) {
      this.uiStore.setParameterSaved(reportExisting.Parameters);
    }
    const parameters = await api.Get(`ODataReportParameters/GetParameters/${this.uiStore?.reportSelected.key}`)
      .then(({data}) => data)
      .catch((err) => []);

    if (this.uiStore?.parametersSaved && this.uiStore?.parametersSaved.length > 0) {
      parameters.forEach((parameter:any) => {
        const existParameter = this.uiStore.parametersSaved.find(x => x.Name === parameter.Name);
        if (existParameter) {
          parameter.Value =  existParameter.Type === 'Date' ? new Date(existParameter.Value)  : existParameter.Value;
        }
        parameter.errorMessage = "";
      })
    }
    this.getInParameters = false;
    this.parameters = parameters;
    return parameters;
  }

  @action async getData(parameters: any[]) {
    this.showDialogConfirm = false;
    this.isImporting = true;
    const reportExisting = await currentSheetHasReport();
    const records = await api.Post(`ODataReports/GenerateDataInReport/${this.uiStore.reportSelected.key}`, parameters)
      .then(({data}) => data)
      .catch((err) => {
        console.log('got error from getData action');
        console.log(err);
        this.uiStore.actionErrorMsg = 'Hubo un error al obtener los datos del reporte';
        return { error: true };
      });

    if (typeof records === 'object' && records.error) {
      this.isImporting = false;
      return;
    }

    const totalOfRecords = records.length;

    if (totalOfRecords === 0) {
      this.uiStore.showEmptyDialog = true;
      this.isImporting = false;
      return;
    }

    const worksheetName = this.uiStore.sheetTitle.SheetName;
    let worksheet: Excel.Worksheet;

    if (reportExisting && reportExisting.ReportInfo.key !== this.uiStore.reportSelected.key) {
      let name = `${Date.now()}${this.uiStore.reportSelected.text}`;
      if (name.length > 31) {
        name = name.slice(0, 31);
      }
      worksheet = await createWorksheet(name);
    } else {
      worksheet = await getWorksheet(worksheetName);
    }


    let table : Excel.Table | null =  await getTable(worksheet);
    let rangeDescriptor = '';
    let headers : string[] = [];
    let newTable = false;

    if (totalOfRecords > 0) {
      let firstRecord = records[0];
      rangeDescriptor = getRangeHeader(firstRecord);
      headers = Object.keys(firstRecord);
    }

    const data = this.transformData(records);

    if (table) {
      await resizeHeaders(table, headers, worksheet);
    } else {
      newTable = true;
      // Method to prevent duplicate insertions of table names throughout the document
      const tableNames = await getAllTableNames();
      let currentTableName = this.uiStore.reportSelected.tableName;

      const existing = tableNames.filter((name) => name.startsWith(currentTableName));
      if (existing.length > 0) {
        if (existing.length === 1) {
          currentTableName += '_2';
        } else {
          const numbers = existing
            .filter((x) => x !== currentTableName) // Remove original name that doesn't have underscore
            .map((x) => Number(x.split('_')[1])); // Get only progression numbers
          const max = Math.max.apply(Math, numbers);

          currentTableName += `_${max + 1}`;
        }
        this.uiStore.reportSelected.tableName = currentTableName;
      }
      table = await createTable(worksheet, rangeDescriptor, headers, this.uiStore.reportSelected.tableName);
    }

    if (!newTable) {
      try {
        await clearTable(table);
      } catch (error) {
        await removeAutoFilter(table);
        await clearTable(table);
      }
    }

    await initRecordsInTable(table,data);

    if (!newTable) {
      await reapplyAutoFilter(table);
    }

    await saveReportGenerated(worksheet,parameters, this.uiStore.reportSelected);

    this.isImporting = false;
    await activateWorksheet(worksheet);
    await this.uiStore.checkIfExistsReport();
  }
  @action setPercentageInfo(step: number , total:number) {
    this.percentage = step / total;
    this.stepLabel = `${step + 1} / ${total} registros importados...`;
  }
}
