import { Icon, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { observer, inject } from "mobx-react";
import { Component } from "react";
import PwProps from "../props/PwProps";

const classNames = mergeStyleSets({
  PwSheetHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    marginTop: '12px'
  },
  sheetName: {
    flex: 'auto'
  }
})

@inject('uiStore')
@observer
export default class PwSheetHeader extends Component<PwProps> {
  render() {
    return <div className={ classNames.PwSheetHeader }>
      <Stack className={ classNames.sheetName}>
        <Text variant='xLarge'>
          { this.props.uiStore?.sheetTitle.SheetName }
        </Text>
      </Stack>
      <Stack>
        <Icon iconName="refresh" style={{ fontSize: '20px' }} />
      </Stack>
    </div>
  }
}