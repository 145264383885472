import axios from 'axios';
import { storage } from './Storage';

export default class Api {
  private http = axios.create({
    baseURL: process.env.REACT_APP_ApiUrl,
    headers: {
      module: 'Common'
    }
  });
  private SetHeaders() {
    const token = storage.getItem('token');
    const tenantID = storage.getItem('tenantid');
    this.http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    this.http.defaults.headers.common['TenantId'] = tenantID;
    this.http.defaults.headers.common['Accept-Language'] = "es-MX"
  }

  OData(url:string) {
    this.SetHeaders();
    return this.http.get(`odata/${url}`)
      .then((res) => {
        console.log('got OData res');
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log('got OData error');
        console.log(err);
        return err;
      });
  }

  Get(url:string) {
    this.SetHeaders();
    return this.http.get(`api/${url}`)
      .then((res) => {
        console.log('got Get res');
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log('got Get error');
        console.log(err);
        return err;
      });
  }

  Post(url: string, data: any) {
    this.SetHeaders();
    return this.http.post(`api/${url}`, data);
  }
}

export const api = new Api()
