import { getTheme, Icon, PrimaryButton, Stack,Text, mergeStyleSets, FontWeights, ContextualMenu, IContextualMenuItem} from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import PwProps from "../props/PwProps";
import { storage } from "../utils/Storage";
import { tokenProcess } from "../utils/Token";

const theme = getTheme();


const classNames = mergeStyleSets({
  menuBar: {
    background: theme.palette.themePrimary,
    height: '48px',
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuButton: {
    width: '40px',
    marginRight: '20px'
  }
})

interface PwMenuState {
  isHiddenMenu: boolean
}

@inject('uiStore', 'socketCommunication')
@observer
export default class PwMenu extends Component<PwProps, PwMenuState>{
  private socketUrl = process.env.REACT_APP_Socket || 'http://localhost:8080';
  private items : IContextualMenuItem[] = [
    {
      key: 'settings',
      text: 'Ajustes',
      iconProps: { iconName: 'Settings' }
    },
    {
      key: 'logOut',
      text: 'Cerrar sesión',
      iconProps: { iconName: 'SignOut' },
      onClick: () => { 
        this.logOut()
      },
      href: this.props.uiStore?.logOutUrl,
      target: '_blank'
    }
  ]

  constructor(props:any) {
    super(props);
    this.state = {
      isHiddenMenu: true
    }
  }

  async logOut() {
    let token = storage.getItem('PwToken');
    let authHeaders = {};
    const url = `${this.socketUrl}/logout`;
    if(token) {
      authHeaders = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    } else {
      authHeaders = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }

    try {
      await fetch(url, {
        method: 'Post',
        mode: 'cors',
        ...authHeaders
      })
      .then(data => data.json())
      .then(data => {
        return data;
      });
    } catch (error) {
      console.log(error);
    }

    const gretting = await tokenProcess();

    this.props.socketCommunication?.emit('token', JSON.stringify(gretting));
    //window.open(this.props.uiStore?.logOutUrl, '_blank');
  }
  

  openMenuOrBack() {
    if(this.props.uiStore?.section === 'report') {
      this.props.uiStore?.changeSection('import');
    } else {
      this.setState({
        isHiddenMenu: !this.state.isHiddenMenu
      })
    }
  }

  toggleIsHidden() {
    this.setState({
      isHiddenMenu: true
    })
  }

  render() {
    const { uiStore } = this.props;
    const sectionTitle  = uiStore?.sectionTitle;
   return <div className={classNames.menuBar}>
     <Stack className={classNames.menuButton}>
       <div>
        <PrimaryButton 
          style={{ minWidth: '100%', boxSizing: 'border-box', padding: 0 }} 
          onClick={() => this.openMenuOrBack()} 
          
          >
          <Icon iconName={ this.props.uiStore?.section === 'report' ? 'back' :  'GlobalNavButton' } style={{ fontSize: '20px' }} />
        </PrimaryButton>
       </div>
     </Stack>
     <Stack>
       <Text variant="xLarge" styles={{ root: { margin: 0, color: theme.palette.white, fontWeight: FontWeights.regular } }}>
        { sectionTitle }
       </Text>
     </Stack>
     <ContextualMenu onItemClick={() => this.toggleIsHidden()} onDismiss={() => this.toggleIsHidden()} items={this.items} hidden={this.state.isHiddenMenu}>
       
     </ContextualMenu>
    </div>
  }
}