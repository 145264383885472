class FallBackStorage {
  properties = new Map();

  getItem = (key: any) => {
    return this.properties.get(key);
  }

  getItems = (keys: any[]) => {
    return keys.map(key => ({ [key]: this.properties.get(key) }))
              .reduce((acc, cur) => ({...acc,...cur}), {})
  }

  setItem = (key: any, value: any) => {
    return this.properties.set(key, value);
  }

  removeItem = (key:any) => {
    return this.properties.delete(key)
  }

  getKeys = () => {
    return Array.from(this.properties.keys());
  }

  clear = () => {
    return this.properties.clear();
  }
}

class PwStorage {
  fallbackStorage = new FallBackStorage();

  fromLocalStorage = (localstorage:any, fallbackStorage:any) => {
    try {
      return localstorage()
    } catch (error) {
      return fallbackStorage();
    }
  }

  getItem = (key: string) => this.fromLocalStorage(
    () => window.localStorage.getItem(key),
    () => this.fallbackStorage.getItem(key),
  )
  getItems = (keys: string[]) => this.fromLocalStorage(
    () => keys.map(key => ({ [key]: window.localStorage.getItem(key) }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
    () => this.fallbackStorage.getItems(keys),
  )
  setItem = (key: string, value: string) => this.fromLocalStorage(
    () => window.localStorage.setItem(key, value),
    () => this.fallbackStorage.setItem(key, value)
  )
  removeItem = (key: string) => this.fromLocalStorage(
    () => window.localStorage.removeItem(key),
    () => this.fallbackStorage.removeItem(key)
  )
  getKeys = () => this.fromLocalStorage(
    () => Object.keys(window.localStorage),
    () => this.fallbackStorage.getKeys()
  )
  clearAll = () => this.fromLocalStorage(
    () => window.localStorage.clear(),
    () => this.fallbackStorage.clear()
  )

}



export const storage = new PwStorage();
