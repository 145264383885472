import prefilled from "./prefilled";
import { storage } from "./Storage";

export const tokenProcess = async () => {
  const greeting = {
    auth: '',
    agent: navigator.userAgent || 'unknown-agent',
  }
    let token = storage.getItem('PwToken');
    const url = `${prefilled.socketUrl}/excel/token`;
    //const documentId = Office.context.document.settings.get('documentId');
    let authHeaders = {};

  console.log('token saved: ', token);
    if(token && token !== 'null') {
      authHeaders = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    } else {
      authHeaders = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }

  console.log('*authHeaders');
  console.log(authHeaders);
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);
      await fetch(url, {
        method: 'Post',
        mode: 'cors',
        signal: controller.signal,
        ...authHeaders
      })
      .then(data => data.json())
      .then(data => {
        console.log('se obtuvo el token')
        token = data.token;
        return data;
      })
      clearTimeout(timeoutId);
    } catch (error) {
      console.log('**ocurrio error implementando AbortController-->')
      console.log(error)
      storage.removeItem('PwToken')
      await fetch(url, {
        method: 'Post',
        mode: 'cors',
        ...authHeaders
      })
      .then(data => data.json())
      .then(data => {
        token = data.token;
        return data;
      })
    }

    storage.setItem('PwToken', token);
    greeting.auth = token;
    return greeting;
}
